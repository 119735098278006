.beat-loader {
  flex-grow: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.beat-loader span {
  display: inline-block;
  margin: 2px;
  border-radius: 100%;
  animation-fill-mode: both;
  animation-name: animation-beat-loader;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-duration: 0.7s;
}

.beat-loader span:nth-child(1) {
  animation-delay: 0s;
}

.beat-loader span:nth-child(2) {
  animation-delay: 0.35s;
}

.beat-loader span:nth-child(3) {
  animation-delay: 0s;
}

@keyframes animation-beat-loader {
  50% {
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
    opacity: 0.2;
  }
  100% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
