.avatarImage {
  box-shadow: 2px 2px 7px 1px darkgray;
  margin-right: 6px;
}

/*
contain: The content is resized to fit its container but it keeps its aspect ratio no matter what.
none: Here, the content is not resized.
scale-down: The image is scaled down to the smallest version of none or contain.
*/

/* Scope object-fit to avatarImage only and not all app image */
.avatarImage > img {
  object-fit: scale-down !important;
}

.profileImage35 > img {
  width: 35px !important;
  height: 35px !important;
}

.profileImage45 > img {
  width: 45px !important;
  height: 45px !important;
}

.profileImage50 > img {
  width: 50px !important;
  height: 50px !important;
}

.profileImage72 > img {
  width: 72px !important;
  height: 72px !important;
}

.profileImage96 > img {
  width: 96px !important;
  height: 96px !important;
}
